import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/messaging'

export const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyA2hEHq2G32SI80dvv-s_Akg_eQhwy2R2Q',
  authDomain: 'cti-ondemand-255210.firebaseapp.com',
  databaseURL: 'https://cti-ondemand-255210.firebaseio.com',
  projectId: 'cti-ondemand-255210',
  storageBucket: 'cti-ondemand-255210.appspot.com',
  messagingSenderId: '271897390108',
  appId: '1:271897390108:web:26828b86dfb0cec11a83d9'
})
export const firestore = firebaseApp.firestore()

const { Timestamp } = firebase.firestore
export { Timestamp }

// WebPush
export const webPushKey =
  'BKF1nRtZbZgGy9xErku1IMJkAVpXQm752pWmJC2WczR0sVYuNsgwXD0FeT1QbJJve58LJbqBCJWBkAn1O1HesoQ'

export async function getWebPushToken() {
  const messaging = firebaseApp.messaging()
  const retries = 3
  for (let i = 0; i < retries; i++) {
    try {
      const token = await messaging.getToken({ vapidKey: webPushKey })
      console.log('Web push token acquired successfully.')
      return token
    } catch (err) {
      console.log(
        `Retrying in a few secconds, Attempt ${i + 1}/${retries} failed:`,
        err
      )
      if (i < retries) {
        await new Promise((resolve) => setTimeout(resolve, 5000))
      } else {
        throw err
      }
    }
  }
  throw new Error('Failed to acquire web push token.')
}

export async function delWebPushToken() {
  const messaging = firebaseApp.messaging()
  try {
    await messaging.deleteToken()
  } catch (err) {
    console.log(err)
    return false
  }
  return true
}

export default async () => {
  if ('serviceWorker' in navigator) {
    try {
      // サービスワーカーを手動で登録
      const registration = await navigator.serviceWorker.register(
        '/firebase-messaging-sw.js'
      )
      console.log('Service Worker registered with scope:', registration.scope)

      await navigator.serviceWorker.ready
      console.log('Service Worker is active.')
    } catch (error) {
      console.error(
        'Service Worker registration or token retrieval failed:',
        error
      )
    }
  } else {
    console.error('Service Workers are not supported in this browser.')
  }
}
