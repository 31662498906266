import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _019aa478 = () => interopDefault(import('../pages/config.ts' /* webpackChunkName: "pages/config" */))
const _c317af5c = () => interopDefault(import('../pages/forget_password.vue' /* webpackChunkName: "pages/forget_password" */))
const _cf369602 = () => interopDefault(import('../pages/inspire.vue' /* webpackChunkName: "pages/inspire" */))
const _69f9dd64 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0a94ae71 = () => interopDefault(import('../pages/reservation.vue' /* webpackChunkName: "pages/reservation" */))
const _1bda1114 = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))
const _3c8df4fa = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _45c40638 = () => interopDefault(import('../pages/account/notification.vue' /* webpackChunkName: "pages/account/notification" */))
const _33705374 = () => interopDefault(import('../pages/account/overview.vue' /* webpackChunkName: "pages/account/overview" */))
const _61e94268 = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _29b0bdd7 = () => interopDefault(import('../pages/reservations/history.vue' /* webpackChunkName: "pages/reservations/history" */))
const _51b00b6b = () => interopDefault(import('../pages/reservations/list.vue' /* webpackChunkName: "pages/reservations/list" */))
const _3a0b9c2a = () => interopDefault(import('../pages/service/contact.vue' /* webpackChunkName: "pages/service/contact" */))
const _33c5ea2b = () => interopDefault(import('../pages/service/coupon.vue' /* webpackChunkName: "pages/service/coupon" */))
const _9f5994d0 = () => interopDefault(import('../pages/service/news.vue' /* webpackChunkName: "pages/service/news" */))
const _28458c73 = () => interopDefault(import('../pages/service/notifications.vue' /* webpackChunkName: "pages/service/notifications" */))
const _3a263d53 = () => interopDefault(import('../pages/service/privacy.vue' /* webpackChunkName: "pages/service/privacy" */))
const _76c506dc = () => interopDefault(import('../pages/service/terms.vue' /* webpackChunkName: "pages/service/terms" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/config/",
    component: _019aa478,
    pathToRegexpOptions: {"strict":true},
    name: "config"
  }, {
    path: "/forget_password/",
    component: _c317af5c,
    pathToRegexpOptions: {"strict":true},
    name: "forget_password"
  }, {
    path: "/inspire/",
    component: _cf369602,
    pathToRegexpOptions: {"strict":true},
    name: "inspire"
  }, {
    path: "/login/",
    component: _69f9dd64,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/reservation/",
    component: _0a94ae71,
    pathToRegexpOptions: {"strict":true},
    name: "reservation"
  }, {
    path: "/reset_password/",
    component: _1bda1114,
    pathToRegexpOptions: {"strict":true},
    name: "reset_password"
  }, {
    path: "/signup/",
    component: _3c8df4fa,
    pathToRegexpOptions: {"strict":true},
    name: "signup"
  }, {
    path: "/account/notification/",
    component: _45c40638,
    pathToRegexpOptions: {"strict":true},
    name: "account-notification"
  }, {
    path: "/account/overview/",
    component: _33705374,
    pathToRegexpOptions: {"strict":true},
    name: "account-overview"
  }, {
    path: "/account/password/",
    component: _61e94268,
    pathToRegexpOptions: {"strict":true},
    name: "account-password"
  }, {
    path: "/reservations/history/",
    component: _29b0bdd7,
    pathToRegexpOptions: {"strict":true},
    name: "reservations-history"
  }, {
    path: "/reservations/list/",
    component: _51b00b6b,
    pathToRegexpOptions: {"strict":true},
    name: "reservations-list"
  }, {
    path: "/service/contact/",
    component: _3a0b9c2a,
    pathToRegexpOptions: {"strict":true},
    name: "service-contact"
  }, {
    path: "/service/coupon/",
    component: _33c5ea2b,
    pathToRegexpOptions: {"strict":true},
    name: "service-coupon"
  }, {
    path: "/service/news/",
    component: _9f5994d0,
    pathToRegexpOptions: {"strict":true},
    name: "service-news"
  }, {
    path: "/service/notifications/",
    component: _28458c73,
    pathToRegexpOptions: {"strict":true},
    name: "service-notifications"
  }, {
    path: "/service/privacy/",
    component: _3a263d53,
    pathToRegexpOptions: {"strict":true},
    name: "service-privacy"
  }, {
    path: "/service/terms/",
    component: _76c506dc,
    pathToRegexpOptions: {"strict":true},
    name: "service-terms"
  }, {
    path: "/",
    redirect: "/reservation/",
    name: "index"
  }, {
    path: "*",
    redirect: "/reservation/",
    name: "notFound"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
