export const getCenterCoordinates = () => {
  const center: any = {
    lat: 35.2730108,
    lng: 139.583447
  }
  return center
}
export const getDefaultZoomLevel = () => {
  const zoom: number = 13
  return zoom
}
