import { vuexfireMutations } from 'vuexfire'

export const mutations = {
  ...vuexfireMutations,

  updatePhoneNumber(state, { phoneNumber }) {
    state.phoneNumber = phoneNumber
  },
  updateVerCode(state, { verCode }) {
    state.verCode = verCode
  },
  updateUserId(state, { userID }) {
    state.userID = userID
  },
  updateTimeBuffer(state, { timeBuffer }) {
    state.timeBuffer = timeBuffer
  },
  updateNotificationChecked(state, { notificationChecked }) {
    // ログインで notification.permissionがチェックされたフラグ
    state.notificationChecked = notificationChecked
  }
}

export const state = () => ({
  phoneNumber: '',
  verCode: '',
  userID: '',
  timeBuffer: '',
  notificationChecked: false
})
